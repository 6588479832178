import axios from 'axios';
import { useEffect, useState } from 'react';
import AlertComponent from '../components/alert-component';
import { PROXY } from '../components/constants';

export default function Post() {
    const [hideButton, setHideButton] = useState(false);
    const [values, setValues] = useState({
        title: '',
        date: '',
        details: '',
        tags: '',
    });
    const [alert, setAlert] = useState(false);
    const [photo, setPhoto] = useState(null);

    const handlePhoto = (e) => {
        setPhoto(e.target.files[0]);
    };

    useEffect(() => {
        if(alert){
            const timer = setTimeout(() => {
                setAlert(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [alert])

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!photo || !values.title || !values.date || !values.details) {
            setAlert('Please fill in all fields and select a photo.');
            return;
        }

        setAlert('');

        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('date', values.date);
        formData.append('details', values.details);
        formData.append('tags', values.tags);
        formData.append('image', photo);

        try {
            const response = await axios.post(`${PROXY}/add_posts`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });            

            if (response.data.success) {
                setAlert('Post added successfully');
                setValues({
                    title: '',
                    date: '',
                    details: '',
                    tags: '',
                });
                setPhoto(null);
            } else {
                setAlert(response.data.message || 'An error occurred while uploading.');
            }
        } catch (error) {
            console.error('Error:', error);
            setAlert('An error occurred while uploading.');
        }
    };

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ${hideButton ? 'hidden' : ''}`}>
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                <form onSubmit={handleSubmit}>
                    <div className="mb-4 text-black">
                        <h2 className='mb-5'>Please Fill Out Every Field...</h2>
                        <input
                            type="text"
                            placeholder="Title... (Required)"
                            className="w-full p-2 border border-gray-300 rounded mb-2 text-black"
                            required
                            value={values.title}
                            onChange={(e) => setValues({...values, title: e.target.value})}
                        />
                        <input
                            type="text"
                            placeholder="Date... (Weekday, Month Day, Year) (Required)"
                            className="w-full text-black p-2 border border-gray-300 rounded"
                            required
                            value={values.date}
                            onChange={(e) => setValues({...values, date: e.target.value})}
                        />
                    </div>
                    <textarea
                        placeholder="Input Details... (Required)"
                        className="w-full text-black p-2 border border-gray-300 rounded"
                        required
                        value={values.details}
                        onChange={(e) => setValues({...values, details: e.target.value})}
                    />
                    <input
                        type='text'
                        placeholder="Tags... (Required)"
                        value={values.tags}
                        className="w-full text-black p-2 border border-gray-300 rounded mb-5"
                        onChange={(e) => setValues({...values, tags: e.target.value})}
                    />
                    <input
                        type="file"
                        className="mb-4 text-black"
                        onChange={handlePhoto}
                    />
                    <div className="flex gap-2 justify-end">
                        <button className="bg-red-500 text-white p-2 rounded" onClick={() => setHideButton(!hideButton)}>Cancel</button>
                        <button type="submit" className="bg-black text-white p-2 rounded">Submit</button>
                    </div>
                </form>
                {alert && (
                    <AlertComponent message={'Posted!!'}/>
                )}
            </div>
        </div>
    );
}

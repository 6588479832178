import axios from 'axios';
import { useEffect, useState } from 'react';
import { PROXY } from '../components/constants';

export default function Feedback() {
    const [feedback, setFeedback] = useState([]);
    const [hideBut, setHideBut] = useState(false);

    useEffect(() => {
        fetchFeedback();
    }, []);

    const fetchFeedback = () => {
        axios.get(`${PROXY}/fetch_feedback`)
        .then(res => {
            setFeedback(res.data.feedback);
        })
        .catch(err => console.error(err));
    };

    const approveFeedback = (approvedTitle, approvedDetails) => {
        axios.post(`${PROXY}/approve_feedback`, { title: approvedTitle, details: approvedDetails })
        .then(res => {
            console.log(res);
            fetchFeedback(); // Refresh feedback after approval
            deleteFeedback(res.data.id)
        })
        .catch(err => console.error(err));
    }

    const deleteFeedback = (id) => {
        axios.delete(`${PROXY}/feedback/${id}`)
        .then(res => {
            setFeedback(feedback.filter(feed => feed.id !== id));
            console.log(res);
        })
        .catch(err => console.error(err));
    }

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ${hideBut ? 'hidden' : ''}`}>
            <div className="bg-white rounded-lg p-5 h-1/2 w-1/2 text-black">
               <div className='flex justify-between'>
                    <h2 className='w-1/3'>Title</h2>
                    <h2 className='w-1/3'>Feedback</h2>
                    <h2 className='w-1/3'>Approve/Decline</h2>
               </div>
                {feedback.map((feed) => {
                    return (
                        <div key={feed.id} className='flex justify-between text-black'>
                            <h2 className='w-1/3'>{feed.title}</h2>
                            <h2 className='w-1/3'>{feed.details}</h2>
                            <div className='flex gap-5 w-1/3'>
                                <p onClick={() => approveFeedback(feed.title, feed.details)}>ACCEPT</p>
                                <p onClick={() => deleteFeedback(feed.id)}>DECLINE</p>
                            </div>
                        </div>
                    )
                })}
                <h2 onClick={() => setHideBut(!hideBut)} className='bottom-0'>CLOSE</h2>
            </div>
        </div>
    )
}
